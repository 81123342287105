<template>
  <a-layout id="components-layout-demo-responsive">
    <a-layout-sider
      breakpoint="lg"
      collapsed-width="0"
      @collapse="onCollapse"
      @breakpoint="onBreakpoint"
    >
      <div class="logo">
        <img :src="require('@/assets/img/logo.png')" />
      </div>

      <a-menu theme="dark" mode="inline">
        <a-menu-item :key="1">
          <router-link :to="{ name: 'AdminWelcome' }">
            <a-icon type="home" />
            <span class="nav-text"> Dashboard </span>
          </router-link>
        </a-menu-item>
        <!-- <a-menu-item :key="2">
          <router-link :to="{ name: 'ManageAboutUs' }">
            <a-icon type="bulb" />
            <span class="nav-text"> About Us</span>
          </router-link>
        </a-menu-item> -->
        <a-menu-item key="3">
          <router-link :to="{ name: 'ListRegisterUser' }">
            <a-icon type="user" />
            <span class="nav-text">User Register</span>
          </router-link>
        </a-menu-item>

        <a-menu-item key="4">
          <router-link :to="{ name: 'ListReportUser' }">
            <a-icon type="file-protect" />
            <span class="nav-text">Report</span>
          </router-link>
        </a-menu-item>
        <!-- <a-menu-item key="4">
          <router-link :to="{ name: 'ListAdminAgenda' }">
            <a-icon type="book" />
            <span class="nav-text">Agenda</span>
          </router-link>
        </a-menu-item> -->
        <!-- <a-menu-item key="5">
          <router-link :to="{ name: 'ListFaq' }">
            <a-icon type="read" />
            <span class="nav-text">FAQ</span>
          </router-link>
        </a-menu-item> -->

        <!-- <a-menu-item key="6">
          <router-link :to="{ name: 'ListGallery' }">
            <a-icon type="file-image" />
            <span class="nav-text">Gallery</span>
          </router-link>
        </a-menu-item> -->
      </a-menu>
    </a-layout-sider>
    <a-layout>
      <a-layout-header
        style="
          background: rgb(64 169 255);padding: 0px;border-bottom: 4px solid rgb(0 79 164)
        "
      >
        <a-row type="flex">
          <a-col flex="1 1 200px"></a-col>
          <a-col flex="0 1 300px">
            <div class="topmenuright">
              <div class="menualigntop">
                <div>
                  <a-dropdown :trigger="['click']">
                    <a
                      class="ant-dropdown-link"
                      @click="(e) => e.preventDefault()"
                    >
                      <a-avatar icon="user" />
                      <span>{{ profiles }}</span>
                    </a>
                    <div slot="overlay" class="customnotiftop profilenotif">
                      <div class="profiletopright">
                        <ul>
                          <li>
                            <router-link to="#" @click.native="editprofile"
                              ><a-icon type="user" /> Profile</router-link
                            >
                          </li>
                          <li>
                            <router-link to="#" @click.native="EditPassword"
                              ><a-icon type="key" /> Password</router-link
                            >
                          </li>
                          <li>
                            <router-link to="#" @click.native="Logout"
                              ><a-icon type="poweroff" /> Logout</router-link
                            >
                          </li>
                        </ul>
                      </div>
                    </div>
                  </a-dropdown>
                </div>
              </div>
            </div>
          </a-col>
        </a-row>
      </a-layout-header>
      <a-layout-content :style="{ margin: '24px 16px 0' }">
        <div class="titlepage">
          <h3>
            <span v-if="$route.query.title != undefined">
              <a-button @click="back"><a-icon type="arrow-left" /></a-button>
            </span>
            {{ $route.meta.title }}
            <span v-if="$route.query.title != undefined">
              | {{ $route.query.tanggal }} - {{ $route.query.title }}
            </span>
          </h3>
        </div>
        <div :style="{ padding: '24px', background: '#fff' }">
          <router-view />
        </div>

        <div class="footercustom">
          Copyright © 2022 Pasifikinternusa - 2023. All Rights Reserved.
        </div>
      </a-layout-content>
    </a-layout>
  </a-layout>
</template>

<script>
export default {
  computed: {
    profiles() {
      //console.log(this.$store.state.Auth.data.user.fullName);
      return this.$store.state.Auth.data.user.fullName;
    },
  },
  methods: {
    back() {
      this.$router.go(-1);
    },
    onCollapse(collapsed, type) {
      console.log(collapsed, type);
    },
    onBreakpoint(broken) {
      console.log(broken);
    },
    Logout() {
      this.$store.dispatch("Logout");
    },
  },
};
</script>
<style src="@/assets/css/administrator.css" lang="css"></style>
<style>
#components-layout-demo-responsive .logo {
  height: 32px;
  background: rgba(255, 255, 255, 0.2);
  margin: 16px;
}
</style>
